import * as DateUtils from './Date'
import * as FormUtils from './Form'
import * as GraphQLUtils from './GraphQL'
import * as MeUtils from './Me'
import * as NumbersUtils from './Numbers'
import * as ObjectUtils from './Object'
import * as PromiseUtils from './Promises'
import * as QueryUtils from './Query'
import * as RoomUtils from './Room'
import * as SelectUtils from './Selects'
import * as StringsUtils from './Strings'
import * as TableUtils from './Table'
import * as UserUtils from './User'

const Utils = {
  Date: DateUtils,
  Form: FormUtils,
  GraphQL: GraphQLUtils,
  Me: MeUtils,
  Numbers: NumbersUtils,
  Object: ObjectUtils,
  Promise: PromiseUtils,
  Strings: StringsUtils,
  Selects: SelectUtils,
  Table: TableUtils,
  User: UserUtils,
  Room: RoomUtils,
  Query: QueryUtils,
}

export default Utils
