export const DEFAULT_SORT_ORDER = 'desc'
export const DEFAULT_SORT_COLUMN = 'createdAt'

export const ROOM_FOLDER_TYPE = {
  ARCHIVE: 'archive',
  DEFAULT: 'default',
}

export const ROOM_SORT_COLUMN = {
  LAST_OPEN: 'participation.lastTouchedAt',
  PINED_AT: 'participation.pinnedAt',
  CREATED_AT: 'participation.createdAt',
}

export const MAX_LIMIT = 2 ** 32 / 2 - 1 // max safe 32 bit signed int
export const ACTIVE_USER_ROOM_SORT = [
  {
    order: DEFAULT_SORT_ORDER,
    column: ROOM_SORT_COLUMN.CREATED_AT,
  },
  {
    order: DEFAULT_SORT_ORDER,
    column: ROOM_SORT_COLUMN.PINED_AT,
  },
  {
    order: DEFAULT_SORT_ORDER,
    column: ROOM_SORT_COLUMN.LAST_OPEN,
  },
]
