import {
  DEFAULT_SORT_COLUMN,
  DEFAULT_SORT_ORDER,
  MAX_LIMIT,
} from 'Constants/query'

export function getDefaultListVariables({
  sortOrder,
  sortColumn,
  limit,
  roomId,
}) {
  return {
    sort: [
      {
        order: sortOrder ?? DEFAULT_SORT_ORDER,
        column: sortColumn ?? DEFAULT_SORT_COLUMN,
      },
    ],
    limit: limit ?? MAX_LIMIT, // max safe 32 bit signed int
    ...(roomId ? { roomId } : {}),
  }
}
