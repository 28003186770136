import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { color } from '@styled-system/color'
import { layout } from '@styled-system/layout'
import { space } from '@styled-system/space'
import { typography } from '@styled-system/typography'

const centerCss = ({ center }) =>
  center &&
  css`
    text-align: center;
  `

const isPointerCss = ({ isPointer }) =>
  isPointer &&
  css`
    cursor: pointer;
  `

const Text = styled.p`
  margin: 0;
  padding: 0;
  position: relative;

  font-family: inherit;
  font-size: ${mapToTheme('text.fontSize')}px;
  font-weight: ${mapToTheme('text.fontWeight')};
  line-height: ${mapToTheme('text.lineHeight')}px;

  color: ${mapToTheme('text.color')};

  ${color}
  ${layout}
  ${space}
  ${typography}

  ${centerCss}
  ${isPointerCss}
`

Text.propTypes = {
  big: PropTypes.bool,
  bold: PropTypes.bool,
  bolder: PropTypes.bool,
  center: PropTypes.bool,
  isPointer: PropTypes.bool,
  light: PropTypes.bool,
  negative: PropTypes.bool,
  positive: PropTypes.bool,
  primary: PropTypes.bool,
  small: PropTypes.bool,
}

Text.defaultProps = {
  big: false,
  bold: false,
  bolder: false,
  center: false,
  isPointer: false,
  light: false,
  negative: false,
  positive: false,
  primary: false,
  small: false,
}

export default Text
